import {React,useState,useEffect} from 'react'
import styled from 'styled-components';
import Catbar from '../components/Catbar';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Navbarshoppage from '../components/Navbarshoppage';
import sliderimage from '../assets/menfashion.jpg';
import Footerwindowsize from '../components/Footerwindowsize';

const Container=styled.div`
    
`
const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1.5s ease;
  background-color: #e9edf6;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;
const Bimage=styled.img`
    height: 65%;
    width: 25%;
    position: absolute;    
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
`
const Bimages=styled.img`
    height: 65%;
    width: 25%;
    position: absolute;    
    left: 35%;
    right: 0;
    top: 30%;
    bottom: 0;
    margin: auto;
    z-index: 2;
`

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #${(props) => props.bg};
  z-index: 4;
`;


const InfoContainer = styled.div`
 
  flex: 1;
  padding: 50px;
`;

const Title = styled.p`
  opacity: 0.8;
  font-size: 170px;
  
  font-weight: 900;
  color: #252524 ;
`;


const Catintropage = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

    return (
        <Container>
          <Navbar/>         
          <Catbar/>  
          <Wrapper>

          <Bimage src={sliderimage}/>
          <Bimages src={sliderimage}/>
          <Slide>            
            <InfoContainer>
              <Title>Men<br/>Fashion</Title>              
            </InfoContainer>
          </Slide>
        
      </Wrapper>
      {windowSize[0]<=500 ? <Footer/>:<Footerwindowsize/>}
        </Container>
    );
}

export default Catintropage;
