import {React,useState,useEffect} from 'react'
import Footerwindow from '../components/Footerwindowsize'
import { Add, Remove } from "@material-ui/icons";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import { useLocation } from "react-router-dom";
import { publicRequest } from "../requestMethods";
import { addToCart } from "../redux/cartRedux";
import { useDispatch } from "react-redux";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import Navbarshoppage from '../components/Navbarshoppage';



const Container = styled.div``;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  ${mobile({ padding: "10px", flexDirection: "column" })}
`;

const ImgContainer = styled.div`
  flex: 1;
`;

const Image = styled.div`
  width: 100%;
  height: 90vh;
  margin-bottom  :350px ;
  ${mobile({ height: "40vh" })}
`;
const slideNumberStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
}

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "10px" })}
`;

const Ratiobox=styled.div`
   flex:1;
   width: 400px;
   height: 100px;
   margin:10px;
   display: flex;
   background-color: #F7F7F7;
   flex-direction: column;
   align-items: center;
   justify-content: center;   
   ${mobile({ width: "330px"})}
`;
const PackTitle = styled.h1`
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 10px;
  
`;
const PackSubitemlist=styled.div` 
    display:flex;
    width: 400px;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: center;    
    ${mobile({ width: "330px"})}
   
    
`;
const PackSubitemlistitem=styled.div`
  width: 60px;
  height: 60px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  
`
const PackSubitemlistitemtitle=styled.h1`
  font-weight: 100;
  font-size: small;
  margin-bottom: 10px;
  color: black;
`
const Title = styled.h1`
  font-weight: 200;
`;

const Desc = styled.html`
  font-size: 20px;
  margin: 50px 0px;
`;

const Price = styled.span`
  font-weight: 100;
  font-size: 25px;
  color: #222;
`;


const FilterContainer = styled.div`
  width: 50%;
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  ${mobile({ width: "100%" })}
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
`;

const FilterColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 0px 5px;
  cursor: pointer;
`;
const FilterColorbox = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  height: 30px;  
  background-color: white;
  border-width: 1px;
  border-style: groove;
  border-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  cursor: pointer;
`;
const FilterColorboxname=styled.p`
  text-transform: uppercase;
`;

const FilterSize = styled.select`
  margin-left: 10px;
  padding: 5px;
`;

const FilterSizeOption = styled.option``;

const AddContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ width: "100%" })}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Amount = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid teal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;

const Button = styled.button`
  padding: 15px;
  border: 2px solid teal;
  background-color: white;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #f8f4f4;
  }
`;
const Table=styled.table`
width: 100%;
  
`;
const Tableth=styled.th`
text-align: center;
${mobile({ fontSize: "15px"})} 

width: 40px;  
`;
const Tablecaption=styled.caption`
  border: 1px solid black;
  border-collapse: collapse;
  ${mobile({ fontSize: "17px"})}
`;
const Tabletr=styled.tr`

`;
const Tabletd=styled.td`
text-align: center;
width: 40px;
${mobile({ fontSize: "15px"})}
`;

const Product = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [Images, setImages] = useState([])
  const [packprice,setPackprice]=useState(0);
  const [product, setProduct] = useState({});
  const [cquantity, setCquantity] = useState(1);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [data,setData]=useState([{}]);  
  const dispatch = useDispatch();
  
  
  useEffect(() => {    
  
    const change= () => {
      setProduct((prev) => ({
        ...prev,cartQuantity:cquantity
      }));
      console.log("updatedquantity",cquantity);
    }
    change();
  }, [cquantity])

  useEffect(() => {    
  
    const change= () => {      
      console.log("updatedproduct",product);
    }
    change();
  }, [product])
  

  useEffect(() => {
    const getProduct = async () => {
    
      try {
        setData([])        
        const res = await publicRequest.get("/products/find/" + id);
        setProduct(res.data);        
        setColor(String(res.data.colour.colname));   
        res.data.colour.images.map((item)=>{

          setData(prev=>([...prev,{"original":item,"thumbnail":item}]
          ))         
        })
        if(!product.hasOwnProperty('cartQuantity')){
          setProduct((prev) => ({
            ...prev,cartQuantity:cquantity
          }));
        }
        console.log("array",data);
      } catch {}
      console.log(product)
    };
    getProduct();
  }, [id]); 

  const handleQuantity = (type) => {
    if (type === "dec") {
      cquantity > 1 && setCquantity(cquantity - 1);
    } else {
      setCquantity(cquantity + 1);
    }
  };

  const handleClick = () => {       
    dispatch(addToCart(product));
  };
  return (
    <Container>
      <Navbar />
      <Announcement />      
      <Wrapper>
        <ImgContainer>
            <Image>
            <ImageGallery items={data} />
          </Image>
        </ImgContainer>
        <InfoContainer>
            <Title>{product && product.gentitle?product.gentitle:"Demo Title"}</Title>
            
            <Price>Unit Price: £ {product.colour && product.colour.unitprice?product.colour.unitprice:"12"}<br/></Price>
            <Price>Pack Price: £ {product && product.packprice?product.packprice:"12"}</Price>
            <FilterContainer>
              <Filter>
                <FilterTitle>COLOR:</FilterTitle>
                
                    {/*<FilterColor color={product.colour && product.colour.colname?product.colour.colname:"red"} key={product.colour && product.colour.colname?product.colour.colname:"red"} onClick={()=>setColor(product.colour && product.colour.colname?product.colour.colname:"red")}/>*/} 
                    <FilterColorbox><FilterColorboxname>{product.colour && product.colour.colname?product.colour.colname:"red"}</FilterColorboxname></FilterColorbox>                                           
              </Filter>              
            </FilterContainer>
            <Ratiobox>
              <PackTitle>Pack Ratio</PackTitle>
              <PackSubitemlist>
              {product.colour && product.colour.sizes?.map(s=>(
                   <PackSubitemlistitem key={s}>                       
                          <PackSubitemlistitemtitle>{s.sname}</PackSubitemlistitemtitle>
                          <PackSubitemlistitemtitle>{s.squantity}</PackSubitemlistitemtitle>                      
                   </PackSubitemlistitem>
                   ))}
              </PackSubitemlist>
            </Ratiobox>
          <AddContainer>
            <AmountContainer>
              <Remove onClick={() => handleQuantity("dec")} />
              <Amount>{cquantity}</Amount>
              <Add onClick={() => handleQuantity("inc")} />
            </AmountContainer>
            <Button onClick={handleClick}>ADD TO CART</Button>
          </AddContainer>
          
          <Desc>
            <h3>Product Description</h3>            
                       
            <br/>
            {product.colour && product.colour.desc?product.colour.desc:"Demo desc"}
            
            <br/>
            <br/>            
            <h4>Size guide</h4>
            <br/>
            <Table>
              <Tablecaption>Global Fashions Size Guide(inches)</Tablecaption>
              <Tabletr><Tableth>SIZE</Tableth><Tableth>UK</Tableth><Tableth>EU</Tableth><Tableth>US</Tableth><Tableth>BUST</Tableth><Tableth>WAIST</Tableth><Tableth>HIPS</Tableth></Tabletr>   
              <Tabletr><Tabletd>XS</Tabletd><Tabletd>6</Tabletd><Tabletd>34</Tabletd><Tabletd>2</Tabletd><Tabletd>32</Tabletd><Tabletd>24.5</Tabletd><Tabletd>35</Tabletd></Tabletr>
              <Tabletr><Tabletd>S</Tabletd><Tabletd>8</Tabletd><Tabletd>36</Tabletd><Tabletd>4</Tabletd><Tabletd>34</Tabletd><Tabletd>26.5</Tabletd><Tabletd>37.5</Tabletd></Tabletr>
              <Tabletr><Tabletd>M</Tabletd><Tabletd>10</Tabletd><Tabletd>38</Tabletd><Tabletd>6</Tabletd><Tabletd>36</Tabletd><Tabletd>28.5</Tabletd><Tabletd>39</Tabletd></Tabletr>
              <Tabletr><Tabletd>L</Tabletd><Tabletd>12</Tabletd><Tabletd>40</Tabletd><Tabletd>8</Tabletd><Tabletd>38</Tabletd><Tabletd>30.5</Tabletd><Tabletd>41</Tabletd></Tabletr>
              <Tabletr><Tabletd>XL</Tabletd><Tabletd>14</Tabletd><Tabletd>42</Tabletd><Tabletd>10</Tabletd><Tabletd>40</Tabletd><Tabletd>32.5</Tabletd><Tabletd>43</Tabletd></Tabletr>

            </Table>
            
            
            </Desc>
        </InfoContainer>
      </Wrapper>      
      {windowSize[0]<=500 ? <Footer/>:<Footerwindow/>}   
    </Container>
  );
};


export default Product;
