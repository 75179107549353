import { ArrowDropDownOutlined, ArrowRightOutlined, Facebook, Instagram, Mail, MailOutline, Phone, Room, Twitter } from "@material-ui/icons";
import styled from "styled-components";
import { mobile } from "../responsive";
import {React,useState,useEffect} from 'react'

const Container = styled.div`  
  display: flex;
  margin-top: 40px; 
  
   bottom: 0;
   min-height: 100px;
   width: 100%;    
  background-color :#121212; 
  ${mobile({ flexDirection:"column" })}
`;

const Title = styled.h1`
  color: #FFF; 
  font-size: 23px;
  font-weight: 900;
  margin-bottom: 10px;
`;
const Titlethird = styled.h1` 
color: #FFF; 
  margin-right :46px ;
  font-size: 23px;
  font-weight: 900;
  margin-bottom: 10px;
`;
const Titlefourth = styled.h1` 
color: #FFF; 
  margin-right :76px ;
  font-size: 23px;
  font-weight: 900;
  margin-bottom: 10px;
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;  
  justify-content: space-between;
  padding: 5px;
`;



const Center = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;  
  justify-content: space-between;
  padding: 5px;
`;



const List = styled.ul`
  margin: 0;
  
  list-style: none;  
  flex-wrap: wrap;
`;

const ListItem = styled.li`
color: #FFF; 
  width: 100%;
  padding: 3px;
  margin-bottom: 3px;
  cursor: pointer;
`;
const Centerright = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  padding: 5px;

`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;  
  justify-content: space-between;
  padding: 5px;

`;
const Bottom=styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items  :baseline ;
  justify-content: space-between;
  padding: 5px;
  
`
const Allrights=styled.h1`
  color: white;
  font-size: 14px;
`
const Instalogo=styled.image`
  height: 50px;
  width: 50px;
`




const Footer = () => {
    const [menua,setMenua]=useState(false);
    const [menub,setMenub]=useState(false);
    const [menuc,setMenuc]=useState(false);
    const [menud,setMenud]=useState(false);

    const handleclicksubmenua=()=>{
      if(menua){
        setMenua(false);
      }else{
        setMenua(true);
      }
    }
    const handleclicksubmenub=()=>{
      if(menub){
        setMenub(false);
      }else{
        setMenub(true);
      }
    }
    const handleclicksubmenuc=()=>{
      if(menuc){
        setMenuc(false);
      }else{
        setMenuc(true);
      }
    }
    const handleclicksubmenud=()=>{
      if(menud){
        setMenud(false);
      }else{
        setMenud(true);
      }
    }

    //////////////////////
    return (
        <Container>
            <Left onClick={handleclicksubmenua}>
                <Title>OUR BRAND</Title>
                {menua?<ArrowDropDownOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>:<ArrowRightOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>}</Left>
                {menua?<List>
                    <ListItem>New Designs</ListItem>
                    <ListItem>Weekly Insights</ListItem>
                    <ListItem>Collaborations</ListItem>
                    <ListItem>Stay in the Loop</ListItem>                    
                </List>:<List></List>}
            
            <Center onClick={handleclicksubmenub}>
                <Title>THE TEAM</Title>
                {menub?<ArrowDropDownOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>:<ArrowRightOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>}</Center>
                {menub?<List>
                    <ListItem>Meet the Team</ListItem>
                    <ListItem>Our Designers</ListItem>
                    <ListItem>Collaborations</ListItem>
                    <ListItem>Careers</ListItem>                    
                </List>:<List></List>}
            
            <Centerright onClick={handleclicksubmenuc}>              
                <Titlethird >FASHION</Titlethird>
                {menuc?<ArrowDropDownOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>:<ArrowRightOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>}</Centerright>
                {menuc?<List>
                    <ListItem>What We Stand For</ListItem>
                    <ListItem>Sustainability</ListItem>
                    <ListItem>Ethical Fashion</ListItem>
                    <ListItem>Slow Fashion</ListItem>                  
                </List>:<List></List>}  
            
            <Right onClick={handleclicksubmenud}>               
                <Titlefourth>LEGAL</Titlefourth>
                {menud?<ArrowDropDownOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>:<ArrowRightOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>}
                </Right>
                {menud?<List>
                    <ListItem>Terms & Conditions</ListItem>
                    <ListItem>Privacy Policy</ListItem>
                    <ListItem>Copyright</ListItem>                                        
                </List>  :<List></List>}                             
            
            <Bottom>
              <Allrights>COPYRIGHT © 2023 Global Fashions</Allrights>
              <Instalogo/>
            </Bottom>
        </Container>
    );
}

export default Footer;
