import Home from "./pages/Home"
import Successfulpayed from "./pages/Successfulpayed"
import Product from "./pages/Product";
import ProductList from "./pages/ProductList";
import Maleproductlist from "./pages/Maleproductlist";
import Femaleproductlist from "./pages/Femaleproductlist";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import { BrowserRouter, Route, Routes,Navigate} from 'react-router-dom'
import Products from "./components/Products";
import { useSelector } from "react-redux";
import Catintropage from "./pages/Catintropage";
import Catintropagefemale from "./pages/Catintropagefemale";

const App = () => {
  const user=useSelector(state=>state.user.currentUser);
  return (        
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/products/man/:category" element={<Maleproductlist/>}/>
          <Route exact path="/products/female/:category" element={<Femaleproductlist/>}/>          
          <Route exact path="/product/:id" element={<Product/>}/>
          <Route exact path="/cart" element={<Cart/>}/>
          <Route exact path="/mancat" element={<Catintropage/>}/>
          <Route exact path="/femalecat" element={<Catintropagefemale/>}/>
          <Route exact path="/Successfulpayed" element={<Successfulpayed/>}/>
          <Route exact path="/login" element={user ? <Navigate to="/"/> :<Login/>} >
            
          </Route>
          <Route exact path="/register" element={user ? <Navigate to="/"/> :<Register/>}/>
        </Routes>     
    
  );
};

export default App;