import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { publicRequest } from "../requestMethods";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {
  clearCart
} from "../redux/cartRedux";



const Successfulpayed = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  //in Cart.jsx I sent data and cart. Please check that page for the changes.(in video it's only data)
  const data = location.state.stripeData;
  const cart = location.state.products;
  console.log("the passed data s",cart.cartItems);
 // const currentUser = useSelector((state) => state.user.currentUser);
  const [orderId, setOrderId] = useState(null);

  window.onpopstate = function(event) {
    navigate('/');
  };

  useEffect(() => {
    const createOrder = async () => {
      try {
        const res = await publicRequest.post("/orders", {          
          products: cart.cartItems.map((item) => ({
            productId: item._id,
            unitprice:item.colour.unitprice,
            packprice:item.packprice,
            title:item.gentitle,
            image:item.colour.images[0],
            quantity: item.cartQuantity,
            
          })),
          subtotal: parseFloat(cart.cartTotalAmount),
          shippingcharges:cart.cartshippingcharges,
          Totalamount:parseFloat(cart.cartTotalAmount)+parseFloat(cart.cartshippingcharges),
          address: data.billing_details.address,
        });
        setOrderId(res.data._id);
        

      } catch {}
    };
    data && createOrder();
  }, [cart, data]);
  useEffect(() => {     
  const reset = () => {
      if(orderId!==null){      
      dispatch(clearCart());
    }
    }
    reset();
  }, [orderId])
  

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {orderId
        ? `Order has been created successfully. Your order number is ${orderId}`
        : `Successfull. Your order is being prepared...`}
      <Link to="/"><button style={{ padding: 10, marginTop: 20 }}>Go to Homepage</button></Link>
    </div>
  );
};

export default Successfulpayed;