import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mobile } from "../responsive";

const Container=styled.div`
    height: 60px;
    background-color: #252524;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${mobile({display:"none" })}

`
const Wrapper=styled.div`
    display: flex;
    background-color: transparent;
    padding-left: 70px;
`
const Catnames=styled.h1`
    font-size: medium;
    color: #F8A7BA ;
    font-style: italic; 
    margin-left: 20px;
    padding:10px ;
    cursor: pointer;
`
const Catbar = () => {
    return (
        <Container>
           <Wrapper>
           <Link style={{textDecoration: 'none'}} to='/products/female/female-newin'><Catnames>New In</Catnames></Link>
           <Link style={{textDecoration: 'none'}} to='/products/female/female-clothing'><Catnames>Clothing</Catnames></Link>
           <Link style={{textDecoration: 'none'}} to='/products/female/female-childrenwear'><Catnames>Childrenwear</Catnames></Link>
           <Link style={{textDecoration: 'none'}} to='/products/female/female-accessories'><Catnames>Accessories</Catnames>   </Link>         
           <Link style={{textDecoration: 'none'}} to='/products/female/female-plussizecurve'> <Catnames>Plus Size & Curve</Catnames></Link>
           <Link style={{textDecoration: 'none'}} to='/products/female/female-blocksizes'><Catnames>Block Sizes</Catnames> </Link>           
           </Wrapper>  
        </Container>
    );
}

export default Catbar;
