import styled from "styled-components"
import CategoryItem from "./CategoryItem"
import { mobile } from "../responsive";
import catcovera from '../assets/homeimagea.jpg';
import catcoverb from '../assets/homeimageb.jpg';

const Container = styled.div`
height: 100vh;
  display: flex;      
  ${mobile({ flexDirection:"column" })}

`;
const Colone=styled.div` 
 height: 600px;
 display: flex;
 flex-direction: column;
`
const Coltwo=styled.div` 
 height: 600px;
 display: flex;
 flex-direction: column;
`
const Catshowdiv=styled.div`
  padding:5px ;
   
`

const Image=styled.img`
  width: 100%;
  height: 90%;
  object-fit: cover;
  
`





function Categories() {
  return (
    <Container>
      <Colone>
        <Catshowdiv>
           <Image src={catcovera}/>                   
        </Catshowdiv>                     
      </Colone>
      <Coltwo>        
      <Catshowdiv>
           <Image src={catcoverb}/>                      
        </Catshowdiv>                   
      </Coltwo>
    </Container>
  )
}

export default Categories