import styled from "styled-components"
import Announcement from "../components/Announcement"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Products from "../components/Products"
import { mobile } from "../responsive";
import {useLocation} from "react-router-dom"
import {React,useState,useEffect} from 'react'
import Footerwindow from '../components/Footerwindowsize'




const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  margin: 20px;
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  ${mobile({ marginRight: "0px" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  ${mobile({ margin: "10px 0px" })}
`;
const Option = styled.option``;



const ProductList = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /////currentpathlocationtrace/////
  const location=useLocation();
  const cat=location.pathname.split("/")[2];
  //////////////
  const[filters,setFilters]=useState({});
  const[sort,setSort]=useState("newest")

  const handleFilters=(e)=>{
    const value=e.target.value;
    setFilters({
      ...filters,
      [e.target.name]:value      
    })
  }
    
  return (
   <Container>
        <Navbar/>
        <Announcement/>
        <Title>{cat}</Title>
        <FilterContainer>            
            <Filter>
                    <FilterText>
                        Filter Products: 
                    </FilterText>
                    <Select name="color" onChange={handleFilters}>
                        <Option disabled> Color</Option>
                        <Option>White</Option>
                        <Option>Black</Option>
                        <Option>Red</Option>                        
                        <Option>Blue</Option>
                        <Option>Yellow</Option>
                        <Option>Green</Option>                        
                    </Select> 
                    <Select name="size" onChange={handleFilters}>
                        <Option disabled> Size</Option>
                        <Option>XS</Option>
                        <Option>S</Option>
                        <Option>M</Option>                        
                        <Option>L</Option>
                        <Option>XL</Option>                                              
                    </Select>
            </Filter>
            <Filter>
                    <FilterText>
                        Sort Products: 
                    </FilterText>
                    <Select onChange={e=>setSort(e.target.value)}>
                        <Option value="newest"> Newest</Option>
                        <Option value="asc">Price (asc)</Option>
                        <Option value="desc">Price (desc)</Option>
                                           
                                               
                    </Select> 
            </Filter>
        </FilterContainer>
        <Products cat={cat} filters={filters} sort={sort}/>
        {windowSize[0]<=500 ? <Footer/>:<Footerwindow/>}  
   </Container>
  )
}

export default ProductList