import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mobile } from "../responsive";

const Container=styled.div`
    height: 60px;
    background-color: #252524;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${mobile({display:"none" })}

`
const Wrapper=styled.div`
    display: flex;
    background-color: transparent;
    padding-left: 70px;
    
`
const Catnames=styled.h1`
    font-size: medium;
    color: #F8A7BA ;
    margin-left: 20px;
    font-style: italic; 
    padding:10px ;
    cursor: pointer;
`
const Catbar = () => {
    return (
        <Container>
           <Wrapper>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-newin'><Catnames>New In</Catnames></Link>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-clothing'><Catnames>Clothing</Catnames></Link>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-childrenwear'><Catnames>Childrenwear</Catnames></Link>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-jeans'><Catnames>Jeans</Catnames></Link>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-joggers'><Catnames>Joggers</Catnames></Link>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-tracksuits'><Catnames>Tracksuits</Catnames></Link>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-footwear'><Catnames>Footwear</Catnames></Link>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-blocksizes'><Catnames>Block Sizes</Catnames></Link>
            <Link style={{textDecoration: 'none'}} to='/products/man/man-shopchristmas'><Catnames>Shop Christmas</Catnames></Link>
           </Wrapper>  
        </Container>
    );
}

export default Catbar;
