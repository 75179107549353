import{createSlice} from "@reduxjs/toolkit"

const initialState={
    cartItems:localStorage.getItem("cartItems")
        ? JSON.parse(localStorage.getItem("cartItems"))
        : [],
        cartTotalQuantity:0,
        cartTotalAmount:0,
        cartshippingcharges:10
}
const cartSlice= createSlice({
    name:"cart",
    initialState,
    reducers:{
        addToCart(state, action) {
          const existingIndex = state.cartItems.findIndex(
            (item) => item._id === action.payload._id
          );
          console.log(existingIndex);
    
          if (existingIndex >= 0) {
            state.cartItems[existingIndex] = {
              ...state.cartItems[existingIndex],
              cartQuantity: state.cartItems[existingIndex].cartQuantity + action.payload.cartQuantity,
            };
            state.cartTotalAmount+=action.payload.cartQuantity*action.payload.packprice;
           
          } else {
            let tempProductItem = { ...action.payload};
            state.cartItems.push(tempProductItem);
            state.cartTotalQuantity+=1;
            state.cartTotalAmount+=action.payload.cartQuantity*action.payload.packprice
           
          }
          localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        increaseCart(state, action) {
            const itemIndex = state.cartItems.findIndex(
              (item) => item._id === action.payload._id
            );     
            state.cartItems[itemIndex].cartQuantity += 1;             
            state.cartTotalAmount+=parseFloat(action.payload.packprice);
      
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
          },
        shippingcharges(state, action) {               
              if(state.cartshippingcharges===10){          
              state.cartshippingcharges=25; 
              }else{
                state.cartshippingcharges=10; 
              }       
              localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            }
        ,
        decreaseCart(state, action) {
          const itemIndex = state.cartItems.findIndex(
            (item) => item._id === action.payload._id
          );
    
          if (state.cartItems[itemIndex].cartQuantity > 1) {
            state.cartItems[itemIndex].cartQuantity -= 1;
            state.cartTotalAmount-=action.payload.packprice;
           
          } else if (state.cartItems[itemIndex].cartQuantity === 1) {
            const nextCartItems = state.cartItems.filter(
              (item) => item._id !== action.payload._id
            );
    
            state.cartItems = nextCartItems;
            state.cartTotalQuantity-=1;
            state.cartTotalAmount-=action.payload.packprice;
            
          }
    
          localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        removeFromCart(state, action) {
          state.cartItems.map((cartItem) => {
            if (cartItem._id === action.payload._id) {
              const nextCartItems = state.cartItems.filter(
                (item) => item._id !== cartItem._id
              );
    
              state.cartItems = nextCartItems;
    
              
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            return state;
          });
        },
        getTotals(state, action) {
          let { total, quantity } = state.cartItems.reduce(
            (cartTotal, cartItem) => {
              const { price, cartQuantity } = cartItem;
              const itemTotal = price * cartQuantity;
    
              cartTotal.total += itemTotal;
              cartTotal.quantity += cartQuantity;
    
              return cartTotal;
            },
            {
              total: 0,
              quantity: 0,
            }
          );
          total = parseFloat(total.toFixed(2));
          state.cartTotalQuantity = quantity;
          state.cartTotalAmount = total;
        },
        clearCart(state, action) {
          state.cartItems = [];
          state.cartTotalAmount=0;
          state.cartTotalQuantity=0;
          state.cartshippingcharges=10;
          localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
          
        },
      }
});

export const {increaseCart, addToCart, decreaseCart, removeFromCart, getTotals, clearCart,shippingcharges}=cartSlice.actions
export default cartSlice.reducer;