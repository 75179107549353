import { Badge } from '@material-ui/core';
import { ArrowDownwardOutlined, ArrowDropDownOutlined, ArrowRight, ArrowRightOutlined, CancelOutlined, MenuOutlined, Search, ShoppingCartOutlined } from '@material-ui/icons';
import {React,useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { mobile } from "../responsive";


const Topbanner =styled.div`
  height:26px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Bannertitle=styled.h1`
  color: white;
  font-weight: 900;
  font-size: 13px;
`
const Container = styled.div`
  background-color: white;
  height: 146px;
  ${mobile({ height: "86px" })}
  z-index: 4;
`;

const Wrapper = styled.div`

  padding: 30px;
  display: flex;
  align-items: center;
 
  justify-content: space-between;
  ${mobile({ padding: 17 })}
  
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: left;
  justify-content: left;
  ${mobile({justifyContent: "left"})}
`;

const Language = styled.span`
  margin-left: 45px;
  font-weight: 900;
  font-size: 15.51px; 
  font-style :italic ;
  color:#252524 ;
  cursor: pointer;
  ${mobile({marginLeft:10 ,display:"none" })}
`;

const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
`;

const Input = styled.input`
  border: none;
  ${mobile({ width: "50px" })}
`;

const Center = styled.div`

  flex: 1;
  text-align: center;
`;

const Logo = styled.h1`
  color:black;    
  text-shadow: 2px 0 black;
  letter-spacing:2px;
  font-weight:bold;  
  font-size: 30px;
  font-family:sans-serif;
  text-align: center;
  font-style: oblique;
  ${mobile({ fontSize: "34px"})}
`;
const Right = styled.div`

  flex: 1;
  display: flex;
  align-items: right;
  justify-content: flex-end;
  ${mobile({justifyContent: "right" })}
`;

const MenuItem = styled.div`  
  cursor: pointer;   
`;
const Menu=styled.div`
  display: none;
  cursor: pointer;
  ${mobile({display: "block" })}

`
const Menumaindiv=styled.div`
background-color: #808080;
  position: fixed;
  height: 100vh;
  width: 70vw;    
  z-index: 4;
  display: flex;
  flex-direction: column;
  transition: all 1s ease;
`
const Menumandiv=styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

`

const Menuwomendiv=styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Menulisttitle=styled.h1`
margin-left: 20px;
  color: white;
  font-size: 20px;
`
const Menulistsubtitle=styled.h1`
  color: white;
  font-size: 15px;
  cursor: pointer;
`
const Menulistsubmenu=styled.ul`   
 
`
const Menulistsub=styled.li`
  padding: 10px;
  list-style: none;
  cursor: pointer;
  
  `

function Navbar() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  const quantity=useSelector(state=>state.cart.cartTotalQuantity)
  const [menu, setmenu] = useState(false);
  const [mansubmenu,setMansubmenu]=useState(false);
  const [womensubmenu,setWomensubmenu]=useState(false);
  console.log(quantity) 
  

  const handleclick=()=>{
    if(menu){
      setmenu(false);
    }else{
      setmenu(true);
    }
  }
  const handleclicksubmenua=()=>{
    if(mansubmenu){
      setMansubmenu(false);
    }else{
      setMansubmenu(true);
    }
  }
  const handleclicksubmenub=()=>{
    if(womensubmenu){
      setWomensubmenu(false);
    }else{
      setWomensubmenu(true);
    }
  }

  return (
    <Container>
      <Menumaindiv style={{left:menu?"0px":"-86vw"}}>
                  <Menulistsub onClick={handleclick}><Badge ><CancelOutlined style={{ color: '#FFF ' ,padding:'10px'}}/></Badge></Menulistsub>
                  <br/>
                  <Menumandiv onClick={handleclicksubmenua}>
                    <Menulisttitle>Man</Menulisttitle>
                    {mansubmenu?<ArrowDropDownOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>:<ArrowRightOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>}
                  </Menumandiv>
                  {mansubmenu?<Menulistsubmenu>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-newin'><Menulistsub><Menulistsubtitle>New In</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-clothing'> <Menulistsub><Menulistsubtitle>Clothing</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-childrenwear'><Menulistsub><Menulistsubtitle>Childrenwear</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-jeans'><Menulistsub><Menulistsubtitle>Jeans</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-joggers'><Menulistsub><Menulistsubtitle>Joggers</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-tracksuits'><Menulistsub><Menulistsubtitle>Tracksuits</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-footwear'><Menulistsub><Menulistsubtitle>Footwear</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-blocksizes'><Menulistsub><Menulistsubtitle>Block Sizes</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/man/man-shopchristmas'><Menulistsub><Menulistsubtitle>Shop Christmas</Menulistsubtitle></Menulistsub></Link>
                  </Menulistsubmenu>:<Menulistsubmenu>
                    <Menulistsub></Menulistsub>                    
                  </Menulistsubmenu>}
                  <br/>
                  <Menuwomendiv onClick={handleclicksubmenub}>
                    <Menulisttitle>Women</Menulisttitle>
                    {womensubmenu?<ArrowDropDownOutlined style={{width:'25px',height:'25px' ,color: '#FFF ',marginRight:'10px'}}/>:<ArrowRightOutlined style={{width:'25px',height:'25px' , color: '#FFF ',marginRight:'10px'}}/>}
                  </Menuwomendiv>
                  {womensubmenu?<Menulistsubmenu>
                    <Link style={{textDecoration: 'none'}} to='/products/female/female-newin'><Menulistsub><Menulistsubtitle>New In</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/female/female-clothing'><Menulistsub><Menulistsubtitle>Clothing</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/female/female-childrenwear'><Menulistsub><Menulistsubtitle>Childrenwear</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/female/female-accessories'><Menulistsub><Menulistsubtitle>Accessories</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/female/female-plussizecurve'><Menulistsub><Menulistsubtitle>Plus Size & Curve</Menulistsubtitle></Menulistsub></Link>
                    <Link style={{textDecoration: 'none'}} to='/products/female/female-blocksizes'><Menulistsub><Menulistsubtitle>Block Sizes</Menulistsubtitle></Menulistsub></Link>                
                  </Menulistsubmenu>:<Menulistsubmenu>
                    <Menulistsub></Menulistsub>                    
                  </Menulistsubmenu>}                 
                </Menumaindiv>
                <Topbanner>
                  <Bannertitle>SHIPPING IN UNITED KINDOM</Bannertitle>
                </Topbanner>
                
        <Wrapper>
            <Left>
                <Menu>
                <Badge onClick={handleclick}
                >
                  <MenuOutlined/>
                </Badge>
                </Menu>
                
                
                <Link style={{textDecoration: 'none'}} to="/mancat"><Language>Men</Language></Link>
                <Link style={{textDecoration: 'none'}} to="/femalecat"><Language>Women</Language></Link>          
            </Left>
            <Center>
              
              <Logo>{windowSize[0]<=500 ?<Link  style={{color:'black', textDecoration: 'none'}} to="/">GFW.</Link>:<Link  style={{color:'black', textDecoration: 'none'}} to="/"> GLOBAL FASHION<br/>WHOLESALE</Link>}</Logo>
              
              
              </Center>
            <Right>                
                <Link to="/cart">
                <MenuItem>
                    <Badge badgeContent={quantity} color="secondary">
                        <ShoppingCartOutlined style={{ color: '#252524 ' }}/>
                    </Badge>
                </MenuItem>
                </Link>
            </Right>
        </Wrapper>
        
    </Container>
  )
}

export default Navbar
