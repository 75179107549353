import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import { useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import sliderimage from '../assets/slidercenterimage.png';
import { Link } from "react-router-dom";

const Container = styled.div`  
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({height:400 })} 
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1.5s ease;
  background-color: #121212;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;
const Bimage=styled.img`
    height: 75%;
    width: 30%;
    position: absolute;    
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    ${mobile({display:"none"})} 
`

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #${(props) => props.bg};
  z-index: 3;
`;

const ImgContainer = styled.div`
  height: 100%;
  flex: 1;
`;

const Image = styled.img`
  height: 80%;
`;

const InfoContainer = styled.div`
 
  flex: 1;
  padding: 50px;
`;

const Title = styled.p`
  opacity: 0.8;
  font-size: 160px;
  font-style: italic;
  font-weight: 900;
  color: #FFF ;
  ${mobile({fontSize:"35px"})}
`;

const Desc = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  font-style :italic ;
  color: #FFF;
  ${mobile({fontSize:"15px"})}
`;

const Button = styled.button`
width: 150px;
  padding: 10px;
  font-size: 15px;
  border-radius:25px;
  font-weight: bold;
  border-color: #252524 ;
  background-color: #252524  ;  
  cursor: pointer;
`;

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }
  };

  return (
    <Container>      
      <Wrapper>

          <Bimage src={sliderimage}/>
          <Slide>            
            <InfoContainer>
              <Title>Global Fashions</Title>
              <Desc>Dress like you're already famous.</Desc>
              <Link style={{textDecoration: 'none'}} to="/mancat"><Button><p style={{color:"white"}}>SHOP NOW</p></Button></Link>
            </InfoContainer>
          </Slide>
        
      </Wrapper>
      
    </Container>
  );
};

export default Slider;
