import { Badge } from '@material-ui/core';
import { Search, ShoppingCartOutlined } from '@material-ui/icons';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { mobile } from "../responsive";

const Container = styled.div`
  height: 120px;
  ${mobile({ height: "50px" })}
`;

const Wrapper = styled.div`

  padding: 30px 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  ${mobile({ padding: "10px 0px" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Language = styled.span`
  margin-left: 45px;
  font-weight: 900;
  font-size: 15.51px; 
  font-style :italic ;
  color:#252524 ;
  cursor: pointer;
  ${mobile({ display: "none" })}
`;

const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
`;

const Input = styled.input`
  border: none;
  ${mobile({ width: "50px" })}
`;

const Center = styled.div`
  flex: 1;
  text-align: center;
`;

const Logo = styled.h1`
  font-weight: bold;
  font-size: 60px;
  ${mobile({ fontSize: "24px" })}
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ flex: 2, justifyContent: "center" })}
`;

const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

function Navbarshoppage() {
  const quantity=useSelector(state=>state.cart.cartTotalQuantity)
  console.log(quantity)

  return (
    <Container>
        <Wrapper>
            <Left>
                <Link style={{textDecoration: 'none'}} to="/mancat"><Language>Men</Language></Link>
                <Link style={{textDecoration: 'none'}} to="/femalecat"><Language>Women</Language></Link>          
            </Left>
            <Center>
              
              <Logo><Link  style={{color: '#252524', textDecoration: 'none'}} to="/">GW.</Link></Logo>
              
              
              </Center>
            <Right>                
                <Link to="/cart">
                <MenuItem>
                    <Badge badgeContent={quantity} color="secondary">
                        <ShoppingCartOutlined style={{ color: '#252524 ' }}/>
                    </Badge>
                </MenuItem>
                </Link>
            </Right>
        </Wrapper>
        
    </Container>
  )
}

export default Navbarshoppage
