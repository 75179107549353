import {React,useState,useEffect} from 'react'
import Footerwindow from '../components/Footerwindowsize'
import Announcement from '../components/Announcement'
import Categories from '../components/Categories'
import Footer from '../components/Footer'

import Navbar from '../components/Navbar'
import Products from '../components/Products'
import Slider from '../components/Slider'
import styled from "styled-components"
import { mobile } from "../responsive";

const Maindiv=styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled.div`
  display: flex;
  height: 595px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  background-color: #F8A7BA ;
  ${mobile({ height:"395px",padding: "0px", flexDirection:"column" })}

`;
const Title=styled.h1`
  font-size: 79.42px;
  font-weight: 900; 
  text-align: center;
  color: #252524;
  ${mobile({ fontSize:"30px" })}
`
const Desc=styled.p`
  font-size: 15.5px;
  font-weight: 900; 
  text-align: center;
`
const Catintro=styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9edf6;
`
const Introcattitle=styled.h1`
  font-size: 170px;
  font-weight: 900;
  color: #252524;
  justify-content: center;
  align-items: center;
  align-self: center;
  ${mobile({ fontSize:"70px" })}
`

const Home = () => {

  const titles=""
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  return (
    <Maindiv>      
        <Navbar/>
        <Slider/>
        <Container>
            <Title>
            a sustainable<br/>shopping experience.
            </Title>
            <Desc>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut<br/> labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco<br/> laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.<br/> in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </Desc>
        </Container>        
        <Categories/> 
        {windowSize[0]<=500 ? <Footer/>:<Footerwindow/>}      
       
    </Maindiv>
  )
}

export default Home
