import { FavoriteBorderOutlined, SearchOutlined, ShoppingCartOutlined } from "@material-ui/icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { publicRequest } from "../requestMethods";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";


const Info=styled.div`
    width: 100%;
    height: 20%;        
    transition: all 0.5s ease;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

`
const Container=styled.div`
    border-width: 0.2px;
       
    padding-bottom :10px ;
    margin:5px;
    min-width: 280px;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;   
    flex-direction: column;    
    &:hover ${Info}{
        opacity: 1;       
    }
     ${mobile({height:"200px",minWidth:"10px",width:"100px",margin:"3px" })}

`
const Price=styled.div`
    display: block;
    width: 280px;
    height: 60px;    
    display: flex;
    flex-direction:column ;
`
const Pricetitle=styled.p`
    font-size: 24px;
    margin-top: 14px;
    display: flex;   
    color: black;
    ${mobile({fontSize:"14px"})}
`
const Pricedesc=styled.p`
    font-size: 15px;
    margin-top: 10px;  
    max-width: 75ch;       
    color: black;
     ${mobile({fontSize:"10px",maxwidth:"20ch"})}
`

const Image=styled.img`
    height: 85%;
    width: 100%;
    z-index: 2;
    ${mobile({height:"570%" ,width:"100%"})}
`

const Icon=styled.div`
    width:40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 10px;
    transition: all 0.5s ease; 
    &:hover{
        background-color: #e9f5f5;
        transform:scale(1.1);
    }
`
const Product = ({item}) => {
        let image=item.colour.images?item.colour.images[0]:"";
        console.log(item)
    return (
        <Link  style={{textDecoration: 'none'}} to={`/product/${item._id}`}>
        <Container>            
            <Image src={image}/>
            <Info>
                <Price>
                <Pricetitle>£{item.colour.unitprice}</Pricetitle>
                <Pricedesc>{item.gentitle}</Pricedesc>
                </Price>
            </Info>
            <Price/>

        </Container>
        </Link>
    );
}

export default Product;
