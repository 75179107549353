import { Add, Remove } from "@material-ui/icons";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import StripeCheckout from "react-stripe-checkout"
import { useState } from "react";
import { useEffect } from "react";
import {publicRequest} from "../requestMethods"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotals,
  removeFromCart,
  increaseCart,
  shippingcharges
} from "../redux/cartRedux";
import Navbarshoppage from "../components/Navbarshoppage";
import { Hidden } from "@material-ui/core";
import Footerwindowsize from "../components/Footerwindowsize";


const KEY=process.env.REACT_APP_STRIPE;

const Container = styled.div``;

const Wrapper = styled.div`

  padding: 20px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
`;

const TopTexts = styled.div`
  ${mobile({ display: "none" })}
`;
const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}

`;

const Info = styled.div`
  flex: 3;
`;

const Product = styled.div`
  background-color: #FFFFFA;
  border-radius: 10px;
  padding: 4px;
  border:1px solid gray;
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 200px;
  ${mobile({ width:"70px"})}
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ProductName = styled.span``;

const ProductId = styled.span`

   ${mobile({ textOverflow:"ellipsis",display:"inline-block",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden",maxWidth:"13ch" })}
`;

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProductAmount = styled.div`
  font-size: 24px;
  margin: 5px;
  ${mobile({ margin: "5px 15px" })}
`;

const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${mobile({ marginBottom: "20px" })}
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 55vh;
`;

const SummaryTitle = styled.h1`
  font-weight: 200;
`;

const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;
const SummaryItemss = styled.div`
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

////////radiobuttons
const Item = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  position: relative;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 19%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  
  background: white;
  border: 1px solid #ccc;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #ccc;    
  }
  &:checked + ${Item} {
    background: black;
    border: 2px solid yellowgreen;
  }
  &:checked + ${RadioButtonLabel} {
    background: yellowgreen;
    border: 1px solid yellowgreen;    
  }
`;
////////////////////

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
`;

const Cart = () => {
  const [select, setSelect] = useState("10");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  
  const handleSelectChange = event => {
    const value = event.target.value;
    setSelect(value);
    handleshippingcharges();
  };

  const cart=useSelector(state=>state.cart)
  const [products,setProducts]=useState(cart);
  const [changeproduct,setChangeproduct]=useState();
  const [stripeToken,setStripeToken]=useState(null);
  const history=useNavigate()
  const [cquantity, setCquantity] = useState(0); 
  const dispatch = useDispatch(); 

  const onToken=(token)=>{
    setStripeToken(token);
  }
  console.log(stripeToken);

  useEffect(() => {
    
  
    const change= () => {
      console.log("shipping value:",select)
    }
    change();
  }, [select])
  

  useEffect(() => {
    
  
    const change= () => {
      console.log("the payload",products);
    }
    change();
  }, [products])

  useEffect(() => {    
  
    const change= () => {
      if(changeproduct !== undefined){
      console.log("change product",changeproduct);
     // dispatch(
      //      updateminusProduct(changeproduct)
       //   );
    }
      else{
        console.log("notchanged")
      }
    }
    change();
  }, [changeproduct])


  useEffect(()=>{
    const makeRequest=async ()=>{
      try {
        const res=await publicRequest.post("/checkout/payment",{
          tokenId:stripeToken.id,
          amount:(getPerc(cart.cartTotalAmount,20)+parseFloat(cart.cartTotalAmount)+parseFloat(cart.cartshippingcharges)) * 100,
         
        });
        history('/Successfulpayed',{state:{
          stripeData: res.data,
          products: cart,}});
          
      } catch (error) {
        console.log(error)
      }
    }
    stripeToken && cart.cartTotalAmount>=1 && makeRequest();
  },[stripeToken,cart.cartTotalAmount,history])


  const handleQuantity = (type,id) => {
    if (type === "dec") {
      cquantity > 1 && setCquantity(cquantity - 1);

      const newState = products.products.map(obj => {
        // 👇️ if id equals 2, update country property
        if (obj._id === id) {
          return {...obj, cquantity: obj.cquantity-1};
        }
  
        // 👇️ otherwise return object as is
        return obj;
      });
      setChangeproduct(newState);
      console.log(products)
     // dispatch(
    //    updateminusProduct({products})
     // );

      
    } else {
      setCquantity(cquantity + 1);
    }
  };
  ////cartfunctionsdispatch 

  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleIncreaseCart = (product) => {
    dispatch(increaseCart(product));
  };
  const handleshippingcharges = () => {
    dispatch(shippingcharges());
  };  
  const handleclearcart = () => {
    dispatch(clearCart());
  };




  return (
    <Container>
      <Navbar />      
      <Wrapper>
        <Title>YOUR BAG</Title>
        <Top>
        <Link  style={{color: '#F8A7BA', textDecoration: 'none'}} to="/"><TopButton>CONTINUE SHOPPING</TopButton></Link>
                  
        </Top>
        <Bottom>
          <Info>
            {cart.cartItems.map(product=>(

           
            <Product>
              <ProductDetail>
                <Image src={product.colour.images[0]} />
                <Details>
                  <ProductName>
                    <b>Product:</b> {product.gentitle}
                  </ProductName>
                  <ProductId>
                    <b>ID:</b> {product._id}
                  </ProductId>
                  <ProductColor color={product.colour.colname} />                  
                </Details>
              </ProductDetail>
              <PriceDetail>
                <ProductAmountContainer>
                  <Add onClick={() => handleIncreaseCart(product)}/>
                  <ProductAmount>{product.cartQuantity}</ProductAmount>
                  <Remove onClick={() => handleDecreaseCart(product)} />
                </ProductAmountContainer>
                <ProductPrice>£ {product.packprice*product.cartQuantity}</ProductPrice>
              </PriceDetail>
            </Product>
             ))}
            <Hr />
            
          </Info>
          <Summary>
            <SummaryTitle>ORDER SUMMARY</SummaryTitle>
            <SummaryItem>
              <SummaryItemText>Subtotal</SummaryItemText>
              <SummaryItemPrice>£ {cart.cartTotalAmount}</SummaryItemPrice>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemText>Vat(20%)</SummaryItemText>
              <SummaryItemPrice>£ {getPerc(cart.cartTotalAmount,20)}</SummaryItemPrice>
            </SummaryItem>
            <SummaryItemss>
            <Item>
        <RadioButton
          type="radio"
          name="radio"
          value="10"
          checked={select === "10"}
          onChange={event => handleSelectChange(event)}
        />
        <RadioButtonLabel />
        <div>United Kingdom:£10</div>
      </Item>
      <Item>
        <RadioButton
          type="radio"
          name="radio"
          value="25"
          checked={select === "25"}
          onChange={event => handleSelectChange(event)}
        />
        <RadioButtonLabel />
        <div>Global Shipping: £25</div>
      </Item>
            </SummaryItemss>
            
            <SummaryItem type="total">
              <SummaryItemText>Total</SummaryItemText>
              <SummaryItemPrice>£ {cart.cartTotalAmount+parseFloat(select)+getPerc(cart.cartTotalAmount,20)}</SummaryItemPrice>
            </SummaryItem>
            <StripeCheckout
              name="Global Fashions Store"
              image="https://avatars.githubusercontent.com/u/1486366?v=4"
              billingAddress
              shippingAddress
              description={`Your total is $${getPerc(cart.cartTotalAmount,20)+parseFloat(cart.cartTotalAmount)+parseFloat(cart.cartshippingcharges)}`}
              amount={(getPerc(cart.cartTotalAmount,20)+parseFloat(cart.cartTotalAmount)+parseFloat(cart.cartshippingcharges)) * 100}
              token={onToken}
              stripeKey={KEY}
            >
              <Button>CHECKOUT NOW</Button>
              </StripeCheckout>
          </Summary>
        </Bottom>
      </Wrapper>
      {windowSize[0]<=500 ? <Footer/>:<Footerwindowsize/>}   
    </Container>
  );
};
function getPerc(amount, percent) {
  const amountDecimals = getNumberOfDecimals(amount);
  const percentDecimals = getNumberOfDecimals(percent);
  const amountAsInteger = Math.round(amount + `e${amountDecimals}`);
  const percentAsInteger = Math.round(percent + `e${percentDecimals}`);
  const precisionCorrection = `e-${amountDecimals + percentDecimals + 2}`;    // add 2 to scale by an additional 100 since the percentage supplied is 100x the actual multiple (e.g. 35.8% is passed as 35.8, but as a proper multiple is 0.358)

  return Number((amountAsInteger * percentAsInteger) + precisionCorrection);
}
function getNumberOfDecimals(number) {
  const decimals = parseFloat(number).toString().split('.')[1];

  if (decimals) {
      return decimals.length;
  }

  return 0;
}


export default Cart;
