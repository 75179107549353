import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Product from "./Product";
import axios from "axios";
import { Bars } from  'react-loader-spinner'
const Maindiv=styled.div`

    display:flex;
    flex-direction: column;
    align-items: center;
`
const Container=styled.div`

    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
`
const Loadmore=styled.div`
margin-top: 30px;
    width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-color: black;
    border-style: groove;
    border-width: 1px;
    cursor: pointer;
`
const Loadmoretext=styled.h1`
    color: black;
    font-size: 15px;
    align-self: center;
    font-style: normal;
`

const Products = ({cat,filters,sort}) => {
    const [loader,setLoader]=useState(true);
    console.log(cat,filters,sort)
    const [productlimit,setProductlimit]=useState(16);
    const [products,setProducts]=useState([]);
    const [filteredProducts,setFilteredProducts]=useState([]);
    ////gettingproductsbased-oncat-ornot
    useEffect(()=>{
        const getProducts=async ()=>{
            try {
                let res=await axios.get(cat ? "https://api.globalfashionwholesale.co.uk/api/products?category="+cat
                                              : "https://api.globalfashionwholesale.co.uk/api/products"
                                              );
                                              setProducts(res.data);
                                              
                                                                            
                
            } catch (err) {
                console.log(err);
            }
            
        };
        getProducts();
    },[cat]);

    ////////filtersapplying-/////////
    useEffect(()=>{
        cat && 
        setFilteredProducts(
            products.filter(item=> Object.entries(filters).every(([key,value])=>
                item[key].includes(value)
            ))
        );
    },[products,cat,filters]);
    //////////sortingproducts/////////

    useEffect(()=>{        
        if (sort==="newest") {
            setFilteredProducts(prev=> 
                [...prev].sort((a,b)=>a.createdAt - b.createdAt)
                );
        } else if (sort==="asc") {
            setFilteredProducts(prev=>
                [...prev].sort((a,b)=> a.price - b.price)
                );
        } else {
            setFilteredProducts(prev=>
                [...prev].sort((a,b)=> b.price - a.price)
                );
        }
    },[sort])

    useEffect(() => {     
    
      const changelength= () => {
        if(products.length>0){
            setLoader(false);
        }
        else{
            setLoader(true);
        }
        
      }
      changelength();
    }, [products.length])
    

    const setlimit= ()=>{
        setProductlimit(productlimit+16);
    }
    
    return (
        <Maindiv>
        <Container>
        <Bars
  height="80"
  width="80"
  color="black"
  ariaLabel="bars-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={loader}
/>
            {cat 
                ? filteredProducts.slice(0,productlimit).map(item=>(<Product item={item} key={item.id}/>))
                : products.slice(0,productlimit).map(item=>(<Product item={item} key={item.id}/>))
            }
           
        </Container>
        {products.length>16? <Loadmore onClick={setlimit}><Loadmoretext>LOAD MORE</Loadmoretext></Loadmore>:<Loadmoretext/>}
        </Maindiv>
    );
}

export default Products;
