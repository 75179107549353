import { Facebook, Instagram, Mail, MailOutline, Phone, Room, Twitter } from "@material-ui/icons";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  margin-top: 294px;
  display: flex; 
  background-color :#F8A7BA; 
`;

const Title = styled.h1`  
  font-size: 23px;
  font-weight: 900;
  margin-bottom: 10px;
`;
const Titlethird = styled.h1` 
  margin-right :46px ;
  font-size: 23px;
  font-weight: 900;
  margin-bottom: 10px;
`;
const Titlefourth = styled.h1` 
  margin-right :76px ;
  font-size: 23px;
  font-weight: 900;
  margin-bottom: 10px;
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
`;



const Center = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
`;



const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;  
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  width: 100%;
  margin-bottom: 3px;
  cursor: pointer;
`;
const Centerright = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;

`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;

`;



const Footerwindowsize = () => {
    return (
        <Container>
            <Left>
                <Title>OUR BRAND</Title>
                <List>
                    <ListItem>New Designs</ListItem>
                    <ListItem>Weekly Insights</ListItem>
                    <ListItem>Collaborations</ListItem>
                    <ListItem>Stay in the Loop</ListItem>                    
                </List>
            </Left>
            <Center>
                <Title>THE TEAM</Title>
                <List>
                    <ListItem>Meet the Team</ListItem>
                    <ListItem>Our Designers</ListItem>
                    <ListItem>Collaborations</ListItem>
                    <ListItem>Careers</ListItem>                    
                </List>
            </Center>
            <Centerright>              
                <Titlethird>FASHION</Titlethird>
                <List>
                    <ListItem>What We Stand For</ListItem>
                    <ListItem>Sustainability</ListItem>
                    <ListItem>Ethical Fashion</ListItem>
                    <ListItem>Slow Fashion</ListItem>                    
                </List>
            </Centerright>
            <Right>               
                <Titlefourth>LEGAL</Titlefourth>
                <List>
                    <ListItem>Terms & Conditions</ListItem>
                    <ListItem>Privacy Policy</ListItem>
                    <ListItem>Copyright</ListItem>                                        
                </List>                             
            </Right>
        </Container>
    );
}

export default Footerwindowsize;
